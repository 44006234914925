import React, { Suspense } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/hero/Hero"
import ServiceHero from "../components/service-hero/ServiceHero"
import AlertBanner from "../components/alert-banner/AlertBanner"
import ContentBlocks from "../components/content-blocks/ContentBlocks"
import GoogleRating from "../components/google-rating-banner/GoogleRating"
import { useIsSsr } from "../assets/js/helper"

const GlobalFooter = React.lazy(() =>
  import("../components/global-footer/GlobalFooter")
)

const IndexPage = ({ location }) => {
  const isSsr = useIsSsr()
  const data = useStaticQuery(getHomePage)
  const [home] = data.allKontentItemPage.nodes
  const { kontentItemSettings, nodeEnvs } = data
  const { whereWeServiceActive, whereWeServiceSlug } = nodeEnvs
  const {
    pestroutes_reviews,
    corporate_head_office,
    reviews,
    review_images,
    enable_alert_banner,
    alert_banner,
    custom_cta_text,
    enable_buy_online,
    google_rating_banner,
  } = kontentItemSettings.elements
  const { tracking_number } = corporate_head_office.value[0].elements
  const showAlert = enable_alert_banner.value[0].codename === "yes"
  const { hash } = location

  const {
    banner,
    content_blocks,
    global_footer,
    seo_metadata__meta_description,
    seo_metadata__meta_title,
  } = home.elements
  const { services } = banner.value[0].elements
  let google_url
  if (google_rating_banner.value[0])
    google_url = google_rating_banner.value[0].elements.google_url

  return (
    <Layout
      mobilePreload={banner.value[0].elements.mobile.value[0].fluid.src}
      hash={hash}
    >
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
      />
      {showAlert && <AlertBanner bannerContent={alert_banner} />}
      {services ? (
        <ServiceHero banner={banner.value[0].elements} />
      ) : (
        <Hero
          banner={banner.value[0].elements}
          phone={tracking_number.value}
          customCTA={custom_cta_text.value}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
        />
      )}
      {google_url && google_url.value && <GoogleRating url={google_url} />}
      <ContentBlocks
        content={content_blocks}
        enableBuyOnline={enable_buy_online}
      />
      {!isSsr && global_footer.value.length > 0 && (
        <Suspense fallback={<div>Loading...</div>}>
          <GlobalFooter
            content={global_footer}
            phone={tracking_number}
            pestroutesReviews={pestroutes_reviews}
            reviewImages={review_images}
            staticReviews={reviews}
          />
        </Suspense>
      )}
    </Layout>
  )
}

const getHomePage = graphql`
  query Home {
    nodeEnvs {
      whereWeServiceActive
      whereWeServiceSlug
    }
    allKontentItemPage(filter: { elements: { slug: { value: { eq: "/" } } } }) {
      nodes {
        elements {
          banner {
            value {
              ... on kontent_item_hero_banner {
                id
                elements {
                  heading {
                    value
                  }
                  subheading {
                    value
                  }
                  mobile: background_image {
                    value {
                      fluid(
                        maxWidth: 768
                        srcSetBreakpoints: [768]
                        quality: 80
                      ) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  desktop: background_image {
                    value {
                      fluid(
                        maxWidth: 1920
                        srcSetBreakpoints: [1920]
                        quality: 80
                      ) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                      description
                    }
                  }
                  secondary_image {
                    value {
                      fluid(maxWidth: 800, quality: 70) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                      description
                    }
                  }
                  tagline {
                    value
                  }
                  buttons {
                    value {
                      ... on kontent_item_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          linked_page {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_anchor_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          anchor_reference {
                            value {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                  background_color {
                    value {
                      name
                    }
                  }
                }
              }
              ... on kontent_item_service_hero_banner {
                id
                elements {
                  background_image {
                    value {
                      fluid(maxWidth: 5000, quality: 80) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                      description
                    }
                  }
                  secondary_image {
                    value {
                      description
                      fluid(maxWidth: 800, quality: 80) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                    }
                  }
                  services {
                    value {
                      ... on kontent_item_service {
                        id
                        elements {
                          name {
                            value
                          }
                          short_description {
                            value
                          }
                          price {
                            value
                          }
                          price_term {
                            value {
                              codename
                            }
                          }
                          heading {
                            value
                          }
                          subheading {
                            value
                          }
                          tagline {
                            value
                          }
                          button {
                            value {
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_simple_hero_banner {
                id
                elements {
                  heading {
                    value
                  }
                  subheading {
                    value
                  }
                  background_image {
                    value {
                      description
                      fluid(maxWidth: 5000, quality: 75) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                    }
                  }
                  background_color {
                    value {
                      name
                    }
                  }
                }
              }
            }
          }
          content_blocks {
            value {
              ... on kontent_item_faq_group {
                id
                elements {
                  faq_list {
                    value {
                      ... on kontent_item_faq {
                        id
                        elements {
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {

                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          question {
                            value
                          }
                          answer {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                        }
                      }
                    }
                  }
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                }
              }
              ... on kontent_item_content_block {
                id
                elements {
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64

                      }
                      description
                    }
                  }
                  buttons {
                    value {
                      ... on kontent_item_anchor_button {
                        id
                        elements {
                          anchor_reference {
                            value {
                              name
                            }
                          }
                          button_text {
                            value
                          }
                        }
                      }
                      ... on kontent_item_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          linked_page {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  image_gallery {
                    value {
                      ... on kontent_item_image_gallery {
                        id
                        elements {
                          gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64

                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  html {
                    value
                  }
                }
              }
              ... on kontent_item_multi_content_block {
                id
                elements {
                  multi_block_heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  content_block {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          content {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64

                              }
                            }
                          }
                          alt_background_color {
                            value {
                              codename
                            }
                          }
                          buttons {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  anchor_reference {
                                    value {
                                      codename
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          image_gallery {
                            value {
                              ... on kontent_item_image_gallery {
                                id
                                elements {
                                  gallery {
                                    value {
                                      ... on kontent_item_image {
                                        id
                                        elements {
                                          single_image {
                                            value {
                                              description
                                              fluid(
                                                maxWidth: 768
                                                quality: 65
                                              ) {
                                                ...KontentAssetFluid_withWebp_noBase64

                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          html {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }

              ... on kontent_item_content_banner {
                id
                elements {
                  background_image {
                    value {
                      fluid(maxWidth: 1920, quality: 85) {

                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  content_slider {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                          content {
                            value
                          }
                          image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_google_rating_banner {
                id
                elements {
                  google_url {
                    value
                  }
                }
              }
              ... on kontent_item_faq {
                id
                elements {
                  question {
                    value
                  }
                  answer {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {

                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on kontent_item_form_banner {
                id
                elements {
                  form_heading {
                    value
                  }
                  form_sub_heading {
                    value
                  }
                  form {
                    value {
                      ... on kontent_item_form {
                        id
                        elements {
                          submission_message_body {
                            value
                          }
                          submission_message_heading {
                            value
                          }
                          submit_button_text {
                            value
                          }
                          fields {
                            value {
                              ... on kontent_item_form_field {
                                id
                                elements {
                                  field_type {
                                    value {
                                      codename
                                    }
                                  }
                                  hidden_field_value {
                                    value
                                  }
                                  label {
                                    value
                                  }
                                  options {
                                    value
                                  }
                                  required {
                                    value {
                                      codename
                                    }
                                  }
                                  form_field {
                                    value {
                                      codename
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image_gallery {
                id
                elements {
                  gallery {
                    value {
                      ... on kontent_item_image {
                        id
                        elements {
                          single_image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {

                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image_navigation {
                id
                elements {
                  heading {
                    value
                  }
                  description {
                    value
                  }
                  navigation {
                    value {
                      ... on kontent_item_image_link {
                        id
                        elements {
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 75) {

                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          internal_link {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_logo_slider {
                id
                elements {
                  logo_slider_heading {
                    value
                  }
                  logo_slider {
                    value {
                      ... on kontent_item_image {
                        id
                        elements {
                          single_image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64

                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_image_link {
                        id
                        elements {
                          image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64

                              }
                              description
                            }
                          }
                          internal_link {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_navigation_block {
                id
                elements {
                  navigation_tiles {
                    value {
                      ... on kontent_item_navigation_tile {
                        id
                        elements {
                          background_image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {

                                ...KontentAssetFluid_withWebp_noBase64
                              }
                              description
                            }
                          }
                          cta {
                            value
                          }
                          description {
                            value
                          }
                          heading {
                            value
                          }
                          icon {
                            value {
                              url
                              description
                            }
                          }
                          internal_link {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_service_area {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_service_comparison {
                id
                elements {
                  services {
                    value {
                      ... on kontent_item_service {
                        id
                        elements {
                          name {
                            value
                          }
                          image_gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 75) {
                                        ...KontentAssetFluid_withWebp_noBase64

                                      }
                                      description
                                    }
                                  }
                                }
                              }
                            }
                          }
                          pests_covered {
                            value {
                              ... on kontent_item_pest {
                                id
                                elements {
                                  name {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          price {
                            value
                          }
                          price_term {
                            value {
                              name
                              codename
                            }
                          }
                          pricing_disclaimer {
                            value
                          }
                          service_highlights {
                            value {
                              ... on kontent_item_service_highlight {
                                id
                                elements {
                                  heading {
                                    value
                                  }
                                  description {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          short_description {
                            value
                          }
                          icon {
                            value {
                              url
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                }
                              }
                            }
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64

                              }
                            }
                          }
                          price_tiers {
                            value {
                              ... on kontent_item_service_price_tier {
                                id
                                elements {
                                  ending_footage {
                                    value
                                  }
                                  footage_type {
                                    value {
                                      codename
                                    }
                                  }
                                  initial_fee {
                                    value
                                  }
                                  price {
                                    value
                                  }
                                  starting_footage {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          cta_form_stub {
            value {
              ... on kontent_item_form_stub_banner {
                id
                elements {
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  heading {
                    value
                  }
                  button_text {
                    value
                  }
                  subheading {
                    value
                  }
                  fields {
                    value {
                      ... on kontent_item_form_field {
                        id
                        elements {
                          form_field {
                            value {
                              codename
                            }
                          }
                          field_type {
                            value {
                              codename
                            }
                          }
                          label {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64

                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64

                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64

                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          linked_item {
            value {
              ... on kontent_item_pest {
                id
                elements {
                  name {
                    value
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 60) {

                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  short_description {
                    value
                  }
                  slug {
                    value
                  }
                }
              }
              ... on kontent_item_service_area {
                id
                elements {
                  label {
                    value
                  }
                  slug {
                    value
                  }
                  service_area_name {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    kontentItemSettings {
      elements {
        google_rating_banner {
          value {
            ... on kontent_item_google_rating_banner {
              id
              elements {
                google_url {
                  value
                }
              }
            }
          }
        }
        pestroutes_reviews {
          value {
            codename
          }
        }
        enable_buy_online {
          value {
            codename
          }
        }
        custom_cta_text {
          value
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                tracking_number {
                  value
                }
                phone_number {
                  value
                }
                name {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                address_line_1 {
                  value
                }
                address_line_2 {
                  value
                }
              }
            }
          }
        }
        reviews {
          value {
            ... on kontent_item_review {
              id
              elements {
                heading {
                  value
                }
                location {
                  value {
                    ... on kontent_item_service_area {
                      id
                      elements {
                        label {
                          value
                        }
                        service_area_name {
                          value
                        }
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                date {
                  value(formatString: "DD MMMM YYYY")
                }
                rating {
                  value {
                    codename
                  }
                }
                review {
                  value
                }
                reviewer {
                  value
                }
                review_source_link {
                  value
                }
              }
            }
          }
        }
        review_images {
          value {
            ... on kontent_item_review_image {
              id
              elements {
                review_image {
                  value {
                    fluid(maxWidth: 768) {
                      ...KontentAssetFluid_withWebp_noBase64

                    }
                    description
                  }
                }
              }
            }
          }
        }
        enable_alert_banner {
          value {
            codename
          }
        }
        alert_banner {
          value {
            ... on kontent_item_alert_banner {
              id
              elements {
                alert_text {
                  value
                }
                background_color {
                  value
                }
                icon {
                  value {
                    codename
                  }
                }
                link {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
