import React from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import * as styles from "./hero.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Hero = ({ banner, customCTA, isLandingPage = false }) => {
  const {
    secondary_image,
    heading,
    subheading,
    buttons,
    tagline,
    desktop,
    mobile,
  } = banner

  const bgOverlay = "PrimaryColor"

  const getButtons = () => {
    if (buttons.value.length > 0) {
      return (
        buttons.value.map(
          ({elements, id }, i) => {
            const {button_text, linked_page, anchor_reference} = elements
            const anchorReference = anchor_reference?.value[0].name || 'form-banner'
            let className = "primary-button"
            if (i === 1) className = "white-accent-ghost-button"
            if (linked_page !== undefined) {
              const { slug } = linked_page.value[0].elements
              return (
                <Link key={id} to={`/${slug.value}`} className={className}>
                  {button_text.value}
                </Link>
              )
            } else {
              return (
                <AnchorLink
                  to={anchorReference}
                  className={className}
                  key={id}
                >
                  {button_text.value}
                </AnchorLink>
              )
            }
          }
        )
      )
    }
  }

  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const secondaryExists = secondary_image?.value[0]
  const getHeroClass = () => {
    if (secondaryExists && !isLandingPage) {
      return `container ${styles.bannerContent}`
    } else if (isLandingPage) {
      return `container ${styles.landingContent}`
    } else return `container ${styles.centeredBannerContent}`
  }

  return (
    <BgImg
      fluid={sources}
      className={isLandingPage ? styles.heroLp : styles.heroBanner}
      alt={desktop.value[0].description}
    >
      <div
        className={`${bgOverlay}Bg ${styles.opacity}`}
        id="hero-override"
      ></div>
      <section className={getHeroClass()}>
        <h1>{heading.value}</h1>
        {subheading && subheading.value !== "" && (
          <RichTextElement value={subheading.value} className />
        )}
        {secondaryExists && isLandingPage && (
          <Img
            fluid={secondary_image.value[0].fluid}
            className={styles.promo}
            alt={secondary_image.value[0].description}
          />
        )}
        <div className={styles.homePageBannerLinks}>
        {getButtons()}
        </div>
        <div className={styles.callText}>
          {tagline.value ? (
            <p className={styles.smallText}>{tagline.value}</p>
          ) : (
            <p className={styles.smallText}>{customCTA}</p>
          )}
        </div>
      </section>
      {secondaryExists && !isLandingPage && (
        <Img
          fluid={secondary_image.value[0].fluid}
          className={styles.secondary}
          alt={secondary_image.value[0].description}
        />
      )}
    </BgImg>
  )
}

export default Hero
